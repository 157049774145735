import { toastReducer, ToastState } from './ToastRedux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { Action, Reducer } from 'redux';
import { authReducer, AuthState } from './AuthRedux';
import thunk from 'redux-thunk';
import { linkReducer, LinkState } from './LinkRedux';
import { reactionReducer, ReactionState } from './ReactionStore';

export default function configureStore() {

   const reducers = {
    loading: reducer,
    auth: authReducer,
    link: linkReducer,
    reaction: reactionReducer,
    toast: toastReducer
};
  const rootReducer = combineReducers({
    ...reducers
      //,
    //  router: connectRouter(history)
  });

  const enhancers = [];
  const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
      rootReducer
      ,undefined,
      compose(applyMiddleware(thunk))
  );
}

export interface ApplicationState {
  loading: LoadingState ;
  auth: AuthState;
  link: LinkState;
 reaction: ReactionState;
 toast: ToastState;
}
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoadingState {
    isLoading: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoadingAction { type: 'LOADING' }
export interface LoadedAction { type: 'LOADED' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoadingAction | LoadedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    loading: () => ({ type: 'LOADING' } as LoadingAction),
    loaded: () => ({ type: 'LOADED' } as LoadedAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<LoadingState> = (state: LoadingState | undefined, incomingAction: Action): LoadingState => {
    if (state === undefined) {
        return { isLoading:false };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADING':
            return { isLoading:true };
        case 'LOADED':
            return { isLoading:false };
        default:
            return state;
    }
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void|any;
}
