import { ApiService } from "./../Service/ApiService";
import { Action, Reducer } from "redux";
import { IAuthModel } from "../Models/IAuthModel";
import { AppThunkAction } from "./ReduxStore";
import * as H from "history";
import Cookies from 'js-cookie'
import {history} from '../index'
export interface LoginAction {
  type: string;
  user: IAuthModel;
}

export interface AuthState {
  isLogging?: boolean;
  isLoggedIn?: boolean;
  user?: IAuthModel;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginAction /**| DecrementCountAction**/;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  login: (email: string, password: string, history: H.History<unknown>): AppThunkAction<LoginAction> => (
    dispatch,
    getState
  ) => {
    dispatch({ type: "LOGING_IN_USER" } as LoginAction);
    let service: ApiService = new ApiService(getState().auth.user?.token ?? "");

    service.login(email, password).then((data) => {
       dispatch({ type: "LOGIN_USER", user: data } as LoginAction);
       if(data?.result??false){
         return history.push("/");
       }
    });
  },
  register: (
    login: string,
    email: string,
    password: string
  ): AppThunkAction<LoginAction> => (dispatch, getState) => {
    let service: ApiService = new ApiService(getState().auth.user?.token ?? "");
    return service.register(login, email, password);
  },
  logout: (): AppThunkAction<LoginAction> => (dispatch, getState) => {
    console.log("logoutaction");
   Cookies.remove("auth");
   history.push("/login");
   dispatch({ type: "LOGOUT_USER" } as LoginAction);
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const authReducer: Reducer<AuthState> = (
  state: AuthState | undefined,
  incomingAction: Action
): AuthState => {
  if (state === undefined) {
    let token = Cookies.get('auth')??"";
    if (token.length > 0) {
      return {
        isLoggedIn: true,
        user: { token: token, errors: [], result: true, refreshToken: "" },
      };
    }
    return { isLoggedIn: false };
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "LOGIN_USER":
    
      if(!action?.user?.result){
          return {isLoggedIn:false};
      }
      console.log(action.user);
      Cookies.set('auth', action.user.token);
      Cookies.set('refreshToken', action.user.refreshToken);
      return { isLoggedIn: true, user: action.user };
    case "LOGING_IN_USER":
      return { isLoggedIn: false, isLogging: true };
      case "LOGOUT_USER":
        console.log("dispatch logout");
      return { isLoggedIn: false, isLogging: false };
    default:
      return state;
  }
};
