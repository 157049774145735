import * as React from 'react';
import LogoComponent from './logoComponent';

export interface IStartPageProps {    
}

export default function StartPage (props: IStartPageProps) {
  return (
    <div>
     <LogoComponent/>
      <p className="text-center lead">Na tej stronie możesz zapisać swoje linki i podzielić się z innymi.</p>
    </div>
  );
}
