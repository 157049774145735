import * as React from 'react';
import { ReactComponent as Logo } from './logo.svg';

export interface ILogoComponentProps {
}

export default function LogoComponent (props: ILogoComponentProps) {
  return (
    <div>
      <Logo id="logoSvg" className="m-2"/>
      <h1 className="text-center display-1">Zapisz.<wbr/>link</h1>
    </div>
  );
}
