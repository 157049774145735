import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from "./reportWebVitals";
import LinkAdd from "./LinkAdd";
import NavBarComponent from "./NavBarComponent";
import { Router, Switch, Route } from "react-router-dom";
import LinkList from "./LinkList";
import { Col, Container, Row } from "react-bootstrap";
import { Provider } from 'react-redux'
import configureStore from "./Redux/ReduxStore";
import TagCard from "./Components/TagCard";
import { Login } from "./Components/LoginComponent";
import LinkCardSingle from "./LinkCardSingle";
import StartPage from "./startPage";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { QueryParamProvider } from "use-query-params";
import LoadingProvider from "./loadingProvider";
import { PrivateRoute } from "./Helpers/PrivateRoute";
import {createBrowserHistory} from "history"
import ToastNotificationHandler from "./ToastNotificationHandler";
import "./index.scss"
import LoadingComponent from "./loadingComponent";
import LinkHubHandler from "./LinkHubHandler";
import { Register } from "./Components/RegisterComponent";

export const store = configureStore();
export const history = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
  <Provider store= {store}>
      <Router history = {history}>
        <NavBarComponent></NavBarComponent>
        {/* <LinkHubHandler/> */}
      <QueryParamProvider ReactRouterRoute={Route}>
        <LoadingProvider>
        <Container> 
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <Switch>
                <PrivateRoute path="/addLink">
                  <LinkAdd />
                </PrivateRoute>
                <PrivateRoute path="/linkList">
                  <LinkList />
                </PrivateRoute>
                <Route path="/register">
                  {/* <LoadingComponent></LoadingComponent> */}
                 <StartPage/>
                  {/* <Register /> */}
                </Route>
                <Route path="/login">
                  <Login/>
                </Route>
                <PrivateRoute path="/test">
                  <TagCard></TagCard>
                </PrivateRoute>
                <Route path="/link/:linkId" >
                  <LinkCardSingle></LinkCardSingle>
                </Route>
                <Route path="/">
                 <StartPage/>
                </Route>
              </Switch>
            </Col> 
          </Row>
          <ToastNotificationHandler/>
        </Container>
        </LoadingProvider>
        </QueryParamProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
initializeIcons(/* optional base url */);

reportWebVitals();


