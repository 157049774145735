import queryString from "query-string";
import { ILinkQuery } from "../Models/ILinkQuery";
import * as H from "history";

export function GetQueryParams(query: string): ILinkQuery {
  let parsed = queryString.parse(query);
  let tagArray = parsed["tagName"] ?? [];
  if (!Array.isArray(tagArray)) {
    tagArray = Array.of(tagArray) as string[];
  }
  return { page: Number(parsed["page"] ?? "1"), tagName: tagArray as string[] };
}
export function SetQueryParams(
  history: H.History<unknown>,
  query: ILinkQuery
): void {
  history.push({
    search: queryString.stringify(query, {
      skipNull: true,
      skipEmptyString: true,
    }),
  });
}
export function AsQueryString(query: ILinkQuery): string {
  return queryString.stringify(query, {
    skipNull: true,
    skipEmptyString: true,
  });
}
