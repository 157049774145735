import {
  Checkbox,
  DefaultButton,
  IStackItemStyles,
  ITag,
  Label,
  PrimaryButton,
  Stack,
  TagPicker,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { ILinkModel } from "./Models/ILinkModel";
import * as LinkStore from "./Redux/LinkRedux";
import { ApplicationState } from "./Redux/ReduxStore";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ApiService } from "./Service/ApiService";
import LoadingComponent from "./loadingComponent";
export interface ILinkAddProps {}

type prop = ApplicationState &
  typeof LinkStore.actionCreators &
  RouteComponentProps &
  any;
export function LinkAdd(props: prop) {
  var [url, setUrl] = React.useState<string>("");
  var [isPrivate, setIsPrivate] = React.useState<boolean>(false);
  var [description, setDescription] = React.useState<string>("");
  var [tags, setTags] = React.useState<ITag[]>([]);
  var history = useHistory();

  let stackItemStyles: IStackItemStyles = {
    root: {
      alignItems: "center",
      display: "flex",
      height: 50,
      justifyContent: "center",
    },
  };

  function getTag(
    filterText: string,
    tagList?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> {
    if (filterText.indexOf(" ") > 0 || filterText === "") {
      return [];
    }
    let service: ApiService = new ApiService(props.user?.token ?? "");
    return service.findTags(filterText.toLocaleLowerCase()).then((ret) => {
      let returnArray: ITag[] = [];
      let t: ITag = { key: 0, name: filterText };
      if (
        !ret
          .map((x) => x.name.toLocaleLowerCase())
          .includes(filterText.toLocaleLowerCase())
      ) {
        returnArray.push(t);
      }
      ret.forEach((x) => {
        let t: ITag = { key: x.tagId, name: x.name };
        if (!tagList?.map((id) => id.key)?.includes(t.key)) {
          console.log(tagList);
          returnArray.push(t);
        }
      });
      return returnArray;
    });
  }

  function saveButtonClick(): void {
    props.postLink(
      {
        url: url,
        userDescription: description,
        isPrivate: isPrivate,
        tags: tags.map((x) => {
          return { tagId: x.key, name: x.name };
        }),
      } as ILinkModel,
      history
    );
  }

  function buttonClick(): void {
    navigator.clipboard.readText().then((clipText) => {
      setUrl(clipText);
    });
  }
  if ((props as ApplicationState).link.isLoading) {
    return <LoadingComponent />;
  }
  return (
    <div>
      <Stack horizontal styles={stackItemStyles}>
        <Stack.Item>
          <DefaultButton text="Wklej" onClick={() => buttonClick()} />
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            value={url}
            onChange={(event, newValue) => {
              setUrl(newValue ?? "");
            }}
          />
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            text="Zapisz"
            onClick={() => saveButtonClick()}
          ></PrimaryButton>
        </Stack.Item>
      </Stack>
      <Checkbox
        label="Link prywatny, niewidoczny przez innych"
        checked={isPrivate}
        onChange={(ev, checked) => {
          setIsPrivate(checked ?? false);
        }}
      />
      <TextField
        label="Krótki opis"
        onChange={(event, newValue) => setDescription(newValue ?? "")}
        multiline
        rows={3}
      />
      <Label>Tagi</Label>
      <TagPicker
        resolveDelay={500}
        removeButtonAriaLabel="Remove"
        //  selectedItems = {tags}
        onChange={(items) => {
          setTags(items?.filter(isDefined) ?? []);
        }}
        onResolveSuggestions={(filter, taglist) => {
          return getTag(filter, taglist);
        }}
      />
    </div>
  );
}
function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}
const mapStateToProps = (state: ApplicationState) => {
  return state;
};
export default connect(mapStateToProps, LinkStore.actionCreators)(LinkAdd);
