import { ApiService } from './../Service/ApiService';
import { Action,Reducer } from 'redux';
import { AppThunkAction } from './ReduxStore';
import { IReactionModel } from '../Models/IReactionModel';
 
export interface ReactionAction { type:ReactionActionType , reaction:IReactionModel[], subjectId: number }
export interface ReactionAddAction { type:ReactionActionType , reaction:IReactionModel }

enum ReactionActionType{
  ADD_REACTIONS = "ADD_REACTIONS",
  ADD_REACTION = "ADD_REACTION",
}
export interface ReactionState {
    reactions?:IReactionModel[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReactionAction | ReactionAddAction/**| DecrementCountAction**/;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getReactionListForSubject: (
    subjectId: number
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    let service: ApiService = new ApiService(appState.auth.user?.token ?? "");
    service.getReaction(subjectId).then((reactions) => {
      dispatch({ type: ReactionActionType.ADD_REACTIONS, reaction: reactions, subjectId });
    });
  },
  addReaction: (
    reaction: IReactionModel
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    let service: ApiService = new ApiService(appState.auth.user?.token ?? "");
    service.addReaction(reaction).then((reaction) => {
      dispatch({ type: ReactionActionType.ADD_REACTION, reaction: reaction });
    });
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reactionReducer: Reducer<ReactionState> = (state: ReactionState | undefined, incomingAction: Action): ReactionState => {
    if (state === undefined) {
        return { reactions: [] };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
      case ReactionActionType.ADD_REACTIONS:
          return {reactions: state.reactions?.filter(x=>x.reactionSubjectId !== (action as ReactionAction).subjectId).concat(action.reaction)};
          case ReactionActionType.ADD_REACTION:
          return {reactions: state.reactions?.concat(action.reaction)};
        default:
            return state;
    }
};


