export function showCookie(name:string): string {
    if (document.cookie !== "") {
        const cookies = document.cookie.split(/; */);

        for (let i=0; i<cookies.length; i++) {
            const cookiePart = cookies[i].split("=");
            const cookieName = cookiePart[0];
            const cookieVal = cookiePart[1];
            if (cookieName === decodeURIComponent(name)) {
                return decodeURIComponent(cookieVal);
            }
        }
    }
    return "";
}