import * as React from "react";
import { Card } from "react-bootstrap";
import { ILinkCommentModel } from "./Models/ILinkCommentModel";
import ReactionComponent from "./ReactionComponent";

export interface ILinkCardCommentProps {
  comment: ILinkCommentModel;
}

export interface ILinkCardCommentState {}

export default class LinkCardComment extends React.Component<
  ILinkCardCommentProps,
  ILinkCardCommentState
> {
  constructor(props: ILinkCardCommentProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div>
        <Card className="m-2">
          <p className="m-2">{this.props.comment.content}</p>
          <div className="d-flex flex-row">
            <div className="float-right mr-auto">
              <ReactionComponent
                reactionSubjectId={this.props.comment.reactionSubjectId}
              ></ReactionComponent>
            </div>
            <p className="text-muted float-left font-weight-bold mr-2">
              {this.props.comment.user?.userName}
            </p>
            <p className="text-muted float-right mr-2">
              {new Date(this.props.comment.createdAt).toLocaleString()}
            </p>
          </div>
        </Card>
      </div>
    );
  }
}
