import { Button, Card} from "react-bootstrap";
import { connect } from "react-redux";
import { ILinkModel } from "./Models/ILinkModel";
import * as ReactionStore from "./Redux/ReactionStore";
import { ApplicationState } from "./Redux/ReduxStore";
import * as LinkStore from "./Redux/LinkRedux";
import LinkComments from "./LinkComments";
import ReactionComponent from "./ReactionComponent";
import { useState } from "react";
import { Link, RouteComponentProps,withRouter } from "react-router-dom";
import { IoLinkOutline } from "react-icons/io5";
import { GetQueryParams, SetQueryParams } from "./Helpers/UrlQueryHelper";

export interface ILinkCardProps {
  link: ILinkModel;
  isCommentSectionOpend?: boolean;
}


type prop2 = ILinkCardProps & ReactionStore.ReactionState& RouteComponentProps & typeof LinkStore.actionCreators;
export  function  LinkCard (props: prop2){

  var [opened,setOpened] = useState<boolean>(props.isCommentSectionOpend??false);

    return (
      <div className="my-1">
        <Card>
          <div className="d-flex flex-row">

        <Card.Img width={100} height={100} loading="lazy" style={{maxHeight: "100px", maxWidth:"100px",margin: "5px", objectFit: "cover"}} className="img-fluid" variant="top" src={props.link.imageUrl} />
          <Card.Body style={{overflow:"auto"}}>
            <a href={props.link.url} target="_blank" className="text-break" rel="noreferrer">
              <div className="text-break">{props.link.title}</div>
            </a>
            {props.link.isPrivate?<p className ="text-muted">Link prywatny</p>:<></>}
            <p className ="text-muted">{props.link.description}</p>
            <p className ="text-muted">{props.link.userDescription}</p>
            <p>{props.link.tags.map(x=>{return<Button key={x.tagId} variant="link" className="p-1"><span onClick = {()=> {
                props.addQueryTag(x.name);
            }} className="badge badge-info p-2">{x.name}</span></Button>})}</p>
          </Card.Body>
            </div>
          <div className="d-flex flex-row align-items-baseline">
            <ReactionComponent
              reactionSubjectId={props.link?.reactionSubjectId}
            ></ReactionComponent>
            <Button
              onClick={() => setOpened(!opened)}
              aria-controls="example-collapse-text"
              aria-expanded={opened}
              variant="link"
              >
              Komentarze: {props.link.comments?.length??0}
            </Button>

            <div className= "ml-auto">
            <span className= "font-weight-bold text-muted p-2">{props.link.user?.userName}</span>
            <div className= "text-muted p-2 d-none d-md-inline  d-lg-inline d-xl-inline">{new Date(props.link.createdAt).toLocaleString()}</div>
            <Button variant="link"><Link to={"/link/" + props.link.linkId}><IoLinkOutline></IoLinkOutline></Link></Button>
            </div>
          </div>
          <LinkComments
            linkComments={props.link.comments}
            linkId={props.link.linkId}
            colapsableOpened={opened}
            />
        </Card>
      </div>
    );
  
}

const mapStateToProps = (state: ApplicationState) => {
  return state.reaction;
};
export default withRouter(connect(mapStateToProps, LinkStore.actionCreators)(LinkCard));

