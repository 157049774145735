import * as React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { IReactionModel } from "./Models/IReactionModel";
import { ApplicationState } from "./Redux/ReduxStore";
import { IoCaretUp, IoCaretDown,IoArrowUpSharp,IoArrowDownSharp } from "react-icons/io5";
import {
  ITooltipHostStyles,
  ITooltipProps,
  TooltipHost,
} from "@fluentui/react";
import * as ReactionStore from "./Redux/ReactionStore";
import { useEffect, useRef, useState } from "react";

export interface IReactionComponentProps {
  reactionSubjectId: number;
}

export interface IReactionComponentState {
  reactions?: IReactionModel[];
}
type prop = IReactionComponentProps &
  ReactionStore.ReactionState &
  typeof ReactionStore.actionCreators;
export function LinkCaReactionComponentrd(props: prop) {
  const ref = React.createRef<any>();
  let [fetched, setFetched] = useState<boolean>(false);
  const onScreen = useOnScreen(ref, "-3px");
  useEffect(() => {
    if (!fetched && onScreen) {
      getReaction(props.reactionSubjectId);
      setFetched(true);
    }
  });
  let calloutProps = { gapSpace: 0 };
  // The TooltipHost root uses display: inline by default.
  // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
  let hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  let tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        {props.reactions
          ?.filter((x) => x.reactionSubjectId === props.reactionSubjectId)
          ?.map((reaction) => {
            return (
              <p className="font-weight-bold text-muted p-2" key={reaction.reactionId}>
                {reaction.user?.userName}  {reaction.reactionType.name=="Up"?<IoArrowUpSharp size={18}/>:<IoArrowDownSharp size={18}/>}
              </p>
            );
          })}
      </>
    ),
  };

  function addReaction(actionType: number): void {
    props.addReaction({
      reactionSubjectId: props.reactionSubjectId,
      reactionType: { reactionTypeId: actionType },
    } as IReactionModel);
  }
  function getReaction(subjectId: number) {
    props.getReactionListForSubject(subjectId);
  }

  return (
    <div ref={ref} className="d-flex align-items-center">
      <Button variant="link" onClick={() => addReaction(1)}>
        <IoArrowUpSharp size={24}/>
      </Button>
      <TooltipHost
        // This id is used on the tooltip itself, not the host
        // (so an element with this id only exists when the tooltip is shown)
        id="123456789"
        tooltipProps={tooltipProps}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <span className="m-2 " style={{fontSize:"1.2rem"}} aria-describedby="123456789">
          {
            props.reactions?.filter(
              (x) => x.reactionSubjectId === props.reactionSubjectId
            )?.length
          }
        </span>
      </TooltipHost>

      <Button variant="link" onClick={() => addReaction(2)}>
        <IoArrowDownSharp size={24}/>
      </Button>
    </div>
  );
}
const mapStateToProps = (state: ApplicationState) => {
  return state.reaction;
};
export default connect(
  mapStateToProps,
  ReactionStore.actionCreators
)(LinkCaReactionComponentrd);

function useOnScreen(ref: React.MutableRefObject<any>, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    { rootMargin: rootMargin }
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
