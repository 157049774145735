import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState, LoadingState } from "./Redux/ReduxStore";

export interface ILoadingComponentProps {}

export function LoadingProvider(props: LoadingState) {
  if (props.isLoading) {
    return (
      <>
        <p>Ładowanie</p>
        {(props as any).children}
        </>
    );
  } else {
    return <>{(props as any).children}</>;
  }
}
export default connect((state: ApplicationState) => state.loading)(
  LoadingProvider
);
