import { ILinkQuery } from "./../Models/ILinkQuery";
import { IPagedModel } from "./../Models/IPagedModel";
import { ApiService } from "./../Service/ApiService";
import { ILinkModel } from "./../Models/ILinkModel";
import { Action, Reducer } from "redux";
import { AppThunkAction } from "./ReduxStore";

import * as H from "history";
import { GetQueryParams, SetQueryParams } from "../Helpers/UrlQueryHelper";
import { history } from "..";

export interface LinkSingleAction {
  type: string;
  links: ILinkModel;
}
export interface LinkPagedAction {
  type: string;
  linksPaged: IPagedModel<ILinkModel>;
  tags?: string[];
}
export interface LinkLoadingAction {
  type: string;
}
export interface LinkPageAction {
  type: string;
  page: number;
}
export interface LinkTagAction {
  type: string;
  tag: string;
}
export interface LinkTagPageAction {
  type: string;
  page: number;
  tag: string;
}

export interface LinkState {
  linkList?: ILinkModel[];
  currentPage?: number;
  pageCount?: number;
  isLoading?: boolean;
  tags?: string[];
}

export type KnownAction =
  // | LinkAction
  LinkSingleAction &
    LinkPagedAction &
    LinkLoadingAction &
    LinkPageAction &
    LinkTagAction & 
    LinkTagPageAction/**| DecrementCountAction**/;

export const actionCreators = {
  getLinkList: (query: ILinkQuery): AppThunkAction<LinkPagedAction | any> => (
    dispatch,
    getState
  ) => {
    dispatch({ type: "LINK_LOADING" });
    let service: ApiService = new ApiService();
    return service.getLinks(query).then((links) => {
      return dispatch({
        type: "LINKS_GET",
        linksPaged: links,
        tags: query.tagName,
      });
    });
  },
  pageChange: (newPage: number): AppThunkAction<LinkPageAction | any> => (
    dispatch,
    getState
  ) => {
    let params = GetQueryParams(history.location.search);
    params.page = newPage;

    SetQueryParams(history, params);
    return dispatch({ type: "LINKS_PAGE_CHANGE", page: newPage });
  },
  addQueryTag: (newTag: string): AppThunkAction<LinkTagAction> => (
    dispatch,
    getState
  ) => {
    console.log("dodanie taga");
    if(getState().link.tags?.some(x=>x == newTag)){
      console.log("tag istnieje");
      return;
    }
    let params = GetQueryParams(history.location.search);
    ((params.tagName as string[])??[]).push(newTag);

    SetQueryParams(history, params);
    return dispatch({ type: "LINKS_TAG_ADD", tag: newTag });
  },
  removeQueryTag: (newTag: string): AppThunkAction<LinkTagAction> => (
    dispatch,
    getState
  ) => {
    let params = GetQueryParams(history.location.search);
    params.tagName = (params.tagName??[]).filter(x=>x !==newTag);

    SetQueryParams(history, params);
    return dispatch({ type: "LINKS_TAG_REMOVE", tag: newTag });
  },

  getSingleLink: (id: number): AppThunkAction<LinkSingleAction | any> => (
    dispatch,
    getState
  ) => {
    dispatch({ type: "LINK_LOADING" });
    const appState = getState();
    let service: ApiService = new ApiService(appState.auth.user?.token ?? "");
    return service.getLink(id).then((links) => {
      return dispatch({ type: "LINKS_GET_SINGLE", links: links });
    });
  },
  setBasedOnQuery: (query: ILinkQuery): AppThunkAction<LinkTagPageAction | any> => (
    dispatch,
    getState
  ) => {
     dispatch({ type: "LINKS_SET_QUERY", page: query.page, tags: query.tagName });
  },
  postLink: (
    link: ILinkModel,
    history: H.History<unknown>
  ): AppThunkAction<LinkSingleAction | any> => (dispatch, getState) => {
    dispatch({ type: "LINK_LOADING" });
    const appState = getState();
    let service: ApiService = new ApiService(appState.auth.user?.token ?? "");
    return service
      .postLink(link)
      .then((links) => {
        dispatch({ type: "LINK_POST", links: links });
        return history.push("/linkList#Link_" + links.linkId);
      })
      .catch((x) => {
        dispatch({ type: "LINK_LOADED" });
      });
  },
};

export const linkReducer: Reducer<LinkState> = (
  state: LinkState | undefined,
  incomingAction: Action
): LinkState => {
  if (state === undefined) {
    return { linkList: [], isLoading: false };
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "LINKS_GET":
      return {...state,
        isLoading: false,
        linkList: action.linksPaged.items as ILinkModel[],
        currentPage: action.linksPaged.currentPage,
        pageCount: action.linksPaged.totalPages,
        tags: action.tags,
      };
    case "LINKS_GET_SINGLE":
      return {...state,
        isLoading: false,
        linkList: (state.linkList ?? []).concat(action.links as ILinkModel),
      };
    case "LINK_POST":
      return {...state,
        isLoading: false,
        linkList: state.linkList?.concat(action.links as ILinkModel),
      };
    case "LINK_GET":
      return {...state,
        isLoading: false,
        linkList: state.linkList?.concat(action.links as ILinkModel),
      };
    case "LINK_LOADING":
      return { ...state,isLoading: true };
    case "LINK_LOADED":
      return { ...state,isLoading: false };
    case "LINKS_PAGE_CHANGE":
      return { ...state,currentPage: action.page };
    case "LINKS_TAG_ADD":
        return {...state,tags: (state.tags??[]).concat(action.tag)}
    case "LINKS_TAG_REMOVE":
          return {...state,tags: (state.tags??[]).filter(x=>x !== action.tag)}  
    case "LINKS_SET_QUERY":
          return {...state,tags: (action.tags??[]),currentPage:action.page}
    default:
      return state;
  }
};
