import * as React from "react";
import { Button, Col, Collapse, Form } from "react-bootstrap";
import { connect } from "react-redux";
import LinkCardComment from "./LinkCardComment";
import { IReactionModel } from "./Models/IReactionModel";
import { AuthState } from "./Redux/AuthRedux";
import { ApplicationState } from "./Redux/ReduxStore";
import { ILinkCommentModel } from "./Models/ILinkCommentModel";
import { ApiService } from "./Service/ApiService";

export interface ILinkCommentsProps {
  linkComments: ILinkCommentModel[];
  linkId: number;
  colapsableOpened?: boolean;
}

export interface ILinkCommentsState {
  newCommentContent?: string;
  colapasableAddNewComment?: boolean;
  reactions?: IReactionModel[];
}
type prop = ILinkCommentsProps & AuthState;
class LinkComments extends React.Component<prop, ILinkCommentsState> {
  constructor(props: prop) {
    super(props);
    this.state = { newCommentContent: "" };
  }

  public render() {
    return <div>{this.renderComments()}</div>;
  }
  renderAddComment():
    | string
    | number
    | boolean
    | {}
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal {
    return (
      <div>
        <Form className="m-2">
          <Form.Row>
            <Col xs={9}>
              <Form.Control
                value={this.state.newCommentContent}
                onChange={(event) => this.comentChange(event)}
                placeholder="Napisz komentarz"
              />
            </Col>
            <Col xs={3}>
              <Button variant="success" onClick={() => this.addComment()}>
                Dodaj komentarz
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    );
  }
  setNewCommentOpen(): void {
    this.setState({
      colapasableAddNewComment: !this.state.colapasableAddNewComment,
    });
  }
  addComment(): void {
    let service: ApiService = new ApiService(this.props.user?.token ?? "");
    service
      .addComment(this.props.linkId, this.state.newCommentContent ?? "")
      .then((x) =>{ (this.props.linkComments ?? []).push(x); this.setState({newCommentContent:""})});
  }

  comentChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void {
    this.setState({ newCommentContent: event.target.value });
  }
  public renderComments() {
    return (
      <div>
        <Collapse in={this.props.colapsableOpened}>
          <div id="example-collapse-text">
            {this.renderAddComment()}
            {this.props.linkComments?.map((x) => {
              return <LinkCardComment key={x.linkCommentId} comment={x} />;
            })}
          </div>
        </Collapse>
      </div>
    );
  }
}
const mapStateToProps = (state: ApplicationState) => {
  return state.auth;
};
export default connect(mapStateToProps)(LinkComments);
