import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AuthState } from "./Redux/AuthRedux";
import { ApplicationState } from "./Redux/ReduxStore";
import { ReactComponent as Logo } from "./logo.svg";

export interface INavBarComponentProps {}
type prop = INavBarComponentProps & AuthState;
class NavBarComponent extends React.PureComponent<prop> {
  public render() {
    return (
      <div>
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
          <Link to="/">
            <Navbar.Brand>
              <Logo style={{ width: "32px", height: "32px" }} /> Zapisz.link
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {this.props.isLoggedIn && (
                <>
                  <Nav.Link
                    as={Link}
                    className="m-1 text-white "
                    eventKey={2}
                    to="/addLink"
                  >
                    Dodaj Link
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey={2}
                    className="m-1 text-white "
                    to="/linkList"
                  >
                    Linki
                  </Nav.Link>
                </>
              )}

              {!this.props.isLoggedIn && (
                <>
                  <Nav.Link
                    as={Link}
                    eventKey={2}
                    className="m-1 text-white "
                    to="/register"
                  >
                    Rejestracja
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    eventKey={2}
                    className="m-1 text-white "
                    to="/login"
                  >
                    Login
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return state.auth;
};
export default connect(mapStateToProps)(NavBarComponent);
