import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as ReduxStore from "./Redux/ReduxStore";
import * as LinkStore from "./Redux/LinkRedux";
import LinkCard from "./LinkCard";
import { ILinkModel } from "./Models/ILinkModel";
import { ApplicationState } from "./Redux/ReduxStore";
import { useEffect } from "react";
import LoadingComponent from "./loadingComponent";

export interface ILinkCardSingleProps {
  linkId?: number;
}
export interface ILinkCardSingleRouterProps {
  linkId?: string;
}

export function LinkCardSingle(
  props: ILinkCardSingleProps & LinkStore.LinkState
) {
  useEffect(() => {
    console.log("placki");
    dispatch(
      LinkStore.actionCreators.getSingleLink(
        (routeParams.linkId as unknown) as number
      )
    );
  }, []);

  const routeParams = useParams<ILinkCardSingleRouterProps>();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("LinkSingle dispatch");
  //   dispatch(LinkStore.actionCreators.getSingleLink((routeParams.linkId as unknown) as number));

  //   // Safe to add dispatch to the dependencies array
  // },[]);
  //const isLoading = useSelector((state)  => (state as ReduxStore.ApplicationState).link.isLoading)??false;
  //console.log(isLoading);
  if (props.isLoading ?? false) {
    return <LoadingComponent/>;
  } else {
    const todo = props.linkList?.filter(
      (x) => x.linkId == ((routeParams.linkId as unknown) as number)
    )[0];
    console.log(todo);
    if (todo != undefined) {
      return (
        <div>
          <LinkCard
            isCommentSectionOpend={true}
            link={todo as ILinkModel}
          ></LinkCard>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default connect((state: ApplicationState) => state.link)(LinkCardSingle);
