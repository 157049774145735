import queryString from 'query-string';
import { ILinkQuery } from './../Models/ILinkQuery';
import { ILinkCommentModel } from './../Models/ILinkCommentModel';
import { ITagModel } from "./../Models/ITagModel";
import { ILinkModel } from "../Models/ILinkModel";
import { IReactionModel } from "../Models/IReactionModel";
import { UserRegistrationRequestDto } from "../Models/UserRegistrationRequestDto";
import { IAuthModel } from "../Models/IAuthModel";
import { IPagedModel } from '../Models/IPagedModel';
import { fetchGetAuthorized, fetchPostAuthorized } from '../Helpers/FeatchHelper';
import { showCookie } from '../Helpers/CookieHelper';
import { AsQueryString } from '../Helpers/UrlQueryHelper';
import Cookies from 'js-cookie';
import process from "process";

export class ApiService {
  private baselink: string = "";
  constructor(private token: string = "", private handle401: () => void = () => { }) {
    let development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    // if (development) {
    //   this.baselink = "https://localhost:5001";
    // } else {
      this.baselink = "https://api.zapisz.link";
    // }
    if (token === "") {
      this.token = showCookie("auth");
    }
  }
  postLink(link: ILinkModel): Promise<ILinkModel> {
    return fetchPostAuthorized<ILinkModel>(this.baselink + "/api/Link", link);
  }
  
  getLinks(query: ILinkQuery): Promise<IPagedModel<ILinkModel>> {
    return fetchGetAuthorized(this.baselink + "/api/Link?" + AsQueryString(query).toString());
  }

  getLink(id: number): Promise<ILinkModel> {
    return fetchGetAuthorized<ILinkModel>(this.baselink + "/api/Link/" + id);
  }

  getReaction(subjectId: number): Promise<IReactionModel[]> {
    return fetchGetAuthorized<IReactionModel[]>(this.baselink + "/api/Reactions/GetReactionForSubject/" + subjectId);
  }

  addReaction(reaction: IReactionModel): Promise<IReactionModel> {
    return fetchPostAuthorized<IReactionModel>(this.baselink + "/api/Reactions", reaction);
  }

  findTags(searchText: string): Promise<ITagModel[]> {
    return fetchGetAuthorized<ITagModel[]>(this.baselink + "/api/Tag/" + searchText.toLowerCase());
  }
  addComment(linkId: number, linkContent: string): Promise<ILinkCommentModel> {
    return fetchPostAuthorized<ILinkCommentModel>(this.baselink + "/api/LinkComment", {
      linkId: linkId,
      content: linkContent,
    });
  }

  register(login: string, email: string, password: string) {
    let body2: UserRegistrationRequestDto;
    body2 = { Email: email, Name: login, Password: password };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body2),
    };
    console.log(body2);
    fetch(this.baselink + "/api/AuthManagement/Register", requestOptions).then(x => { console.log(x) });
  }

  login(email: string, password: string): Promise<IAuthModel> {
    let body2: any;
    body2 = { Email: email, Password: password }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body2),
    };
    return fetch(this.baselink + `/api/AuthManagement/Login`, requestOptions)
      .then(response => { return (response.json() as Promise<IAuthModel>) });

  }

  async refreshToken(refreshToken: string): Promise<IAuthModel> {
    let body: any;
    body = { Token: refreshToken }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    const response = await fetch(this.baselink + `/api/AuthManagement/RefreshToken`, requestOptions);
    if (!response.ok) {
      throw response;
    }
    return await (response.json() as Promise<IAuthModel>);
  }
}
