export default function LoadingComponent() {
  return (
    <div>
      <div className="mt-5 d-flex justify-content-center">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-center">
            <div className="loader" />
          </div>
          <div className="d-flex flex-row justify-content-center">
            <p className="display-3">Ładowanie</p>
          </div>
        </div>
      </div>
    </div>
  );
}
