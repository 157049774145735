import { DefaultPalette } from '@fluentui/react';
import * as React from 'react';
import * as CounterStore from '../Redux/ReduxStore';
import { connect } from 'react-redux';
export interface ITagCardProps {
    value?:string
}
type CounterProps =
    CounterStore.LoadingState &
    typeof CounterStore.actionCreators

 class TagCard extends React.Component<CounterProps> {

     styleborder:React.CSSProperties = {
        border: "1px solid rgb(96, 94, 92)",
        height:"auto",
        backgroundColor:DefaultPalette.neutralLight,
        width: "50px",
        justifyContent : "center"
    };
  public render() {
    return (
      <div>
        {/* <div >{this.props.count}</div>
        <button onClick = {this.props.increment}>Incre</button>
        <button onClick = {this.props.decrement}>Decrem</button> */}
      </div>
    );
  }
}
const mapStateToProps = (state:CounterStore.ApplicationState) => {
  return state.loading;
}
export default connect(
  mapStateToProps,
  CounterStore.actionCreators
)(TagCard);
