import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { ApplicationState } from "../Redux/ReduxStore";

export function PrivateRoute(props: any) {
  
    const auth = useSelector((state: ApplicationState) => state.auth);
    return (
      <Route
        render={({ location }) =>
          auth.isLoggedIn ? (
            props.children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }