import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from './Redux/ReduxStore';
import {actionCreators as toastAction} from "./Redux/ToastRedux";



export default function ToastNotificationHandler () {
  const toasts = useSelector((state:ApplicationState)=> state.toast.toasts);
  const dispatch = useDispatch();

  
  return (
    <div style={{position: "fixed", bottom: "2vh", right:"2vw"}}>
      {toasts?.map(toast=>{
        return (
          <Toast style={{minWidth: "30vw"}} key={toast.key} onClose= {()=> dispatch(toastAction.removeToast(toast))}  
           delay={15000} autohide
          >
          <Toast.Header>
            <strong className="mr-auto">Zapisz.link</strong>
          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
        );
      })}
  </div>
  ); 
}
