import { Action, Reducer } from "redux";
import { AppThunkAction } from "./ReduxStore";
import { ToastModel } from "../Models/ToastModel";
export interface ToastAction {
  type: string;
  toast: ToastModel;
}

export interface ToastState {
  toasts?: ToastModel[];
}

export type KnownAction = ToastAction;


export const actionCreators = {
addToast: (toast: ToastModel): AppThunkAction<ToastAction> => (
    dispatch,
    getState
  ) => {
    dispatch({ type: "ADD_TOAST", toast:toast } as ToastAction);
  },
  removeToast: (toast: ToastModel): AppThunkAction<ToastAction> => (
    dispatch,
    getState
  ) => {
    dispatch({ type: "REMOVE_TOAST", toast:toast } as ToastAction);
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const toastReducer: Reducer<ToastState> = (
  state: ToastState | undefined,
  incomingAction: Action
): ToastState => {
  if (state === undefined) {
    return { toasts: [] };
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ADD_TOAST":
      return { toasts: [...(state.toasts??[]),action.toast] };
      case "REMOVE_TOAST":
      return { toasts: state.toasts?.filter(x=>x !== action.toast) };
    default:
      return state;
  }
};
