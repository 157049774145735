import { IAuthModel } from './../Models/IAuthModel';
import { ApiService } from './../Service/ApiService';
import Cookies from 'js-cookie';
import { store } from '../index';
import { actionCreators } from "../Redux/AuthRedux";
import { actionCreators as toastAction } from "../Redux/ToastRedux";
export async function fetchGetAuthorized<T>(resource: string): Promise<T> {
  return fetchAuthorized("GET", resource);
}
export async function fetchPostAuthorized<T>(resource: string, body: any): Promise<T> {
  return await fetchAuthorized("POST", resource, body);
}
async function fetchAuthorized<T>(method: string, resource: string, body:any = null): Promise<T> {
  let apiService: ApiService = new ApiService();
  let refreshToken: string = Cookies.get('refreshToken') ?? "";
  let maxRetryCount: number = 3;
  let retryCount: number = 0;
  while (retryCount < maxRetryCount) {
    retryCount++;
    let token: string = Cookies.get('auth') ?? "";
    if (retryCount != 1) {
      console.log("RetryCount", retryCount);
    }
    const requestOptions : RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    if(body !== null){
      requestOptions.body = JSON.stringify(body);
    }

    try {
      const x = await fetch(resource,
        requestOptions
      );
      if (x.ok) {
        return x.json() as Promise<T>;
      }
      throw x;
    } catch (rejected) {
      if (rejected.status === 401) {
        console.log("try refresh");
        let newToken: IAuthModel
        try {
          newToken = await apiService.refreshToken(refreshToken);
          Cookies.set('auth', newToken.token);
          Cookies.set('refreshToken', newToken.refreshToken);
        } finally {
          continue;
        }
      }
      return await Promise.resolve({} as T);
    }
  }
  store.dispatch<any>(actionCreators.logout());
  store.dispatch<any>(toastAction.addToast({ key: Math.random().toString(36).substring(7), message: "Błąd autoryzacji! Wylogowano.", addDate: new Date() }));
  return Promise.resolve({} as T);
}