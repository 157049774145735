import React, { ReactElement, useEffect, useRef } from "react";
import { Button, Card, FormControl, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { LinkState, actionCreators } from "./Redux/LinkRedux";
import { ApplicationState } from "./Redux/ReduxStore";
import { BsSearch } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useState } from "react";
import { ApiService } from "./Service/ApiService";
import useOnClickOutside from "./Hooks/clickOutsideHook";
import useDebounce from "./Hooks/debounceHook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GetQueryParams } from "./Helpers/UrlQueryHelper";
interface Props {}

function LinkFilterComponent(
  props: LinkState & typeof actionCreators & RouteComponentProps
): ReactElement {
  var [searchText, setSearchText] = useState<string>("");
  var [tagsArray, setTagArray] = useState<string[]>([]);
  var [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
  const ref = React.createRef<any>();
  const debouncedSearchTerm: string = useDebounce<string>(searchText, 500);
  useOnClickOutside(ref, () => {
    setDropDownVisible(false);
  });

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        let service: ApiService = new ApiService();
        service.findTags(searchText.toLocaleLowerCase()).then((ret) => {
          setTagArray(ret.map((x) => x.name));
          setDropDownVisible(true);
        });
      } else {
        setDropDownVisible(false);
        setTagArray([]);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  useEffect(()=>{

   props.setBasedOnQuery(GetQueryParams(props.history.location.search));
    console.log("query changed");
  },
    [props.history.location.search] // Only call effect if debounced search term changes
  );

  return (
    <div>
      <div style={{ boxSizing: "border-box" }}>
        <InputGroup className="mt-3">
          <FormControl
         
            value={searchText}
            onChange={(value) => setSearchText(value.currentTarget.value)}
            placeholder="Wyszukiwarka"
            aria-label="Wyszukiwarka"
            aria-describedby="basic-addon2"
          />

          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              className={"pt-0 pb-0"}
              onClick={() => seachTags()}
            >
              <BsSearch size={24} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <div
          ref={ref}
          //  onFocus={()=>setDropDownVisible(true)}
          className="px-3"
          style={{
            position: "absolute",
            zIndex: 1000,
            visibility: dropDownVisible ? "visible" : "collapse",
            boxSizing: "border-box",
            left: 0,
            right: 0,
          }}
        >
          <Card>
            {tagsArray.map((x) => (
              <Card
                className="p-2 my-1"
                onClick={() => {
                  props.addQueryTag(x);
                  setDropDownVisible(false);
                }}
                key={x}
              >
                {x}
              </Card>
            ))}
          </Card>
        </div>
      </div>

      <div className="mt-2 pr-1">
        <p>
          {props.tags?.map((x) => {
            return (
              <Button
                key={x}
                variant="link"
                className="p-0 pt-1 pr-3 pl-0"
                onClick={() => addTagToQuery(x)}
              >
                <span className="badge badge-info p-2">
                  {x} <IoClose size="16" />
                </span>
              </Button>
            );
          })}
        </p>
      </div>
    </div>
  );
  function addTagToQuery(x: string): void {
    setSearchText("");
     props.removeQueryTag(x);
  }

  function seachTags(): void {
    if (dropDownVisible) {
      setDropDownVisible(false);
      return;
    }
    if (searchText.localeCompare(debouncedSearchTerm) != 0) {
      let service: ApiService = new ApiService();
      service.findTags(searchText.toLocaleLowerCase()).then((ret) => {
        setTagArray(ret.map((x) => x.name));
        setDropDownVisible(true);
      });
    } else {
      setDropDownVisible(true);
    }
  }
}
const mapStateToProps = (state: ApplicationState) => {
  return state.link;
};

export default withRouter(connect(mapStateToProps, actionCreators)(LinkFilterComponent));
