import { PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../loadingComponent";
import LogoComponent from "../logoComponent";
import { actionCreators } from "../Redux/AuthRedux";
import { ApplicationState } from "../Redux/ReduxStore";

export interface IRegisterProps {}
export interface IRegisterState {
  login: string;
}

export function Login() {
  var [email, setEmail] = React.useState<string>("");
  var [password, setPassword] = React.useState<string>("");
  const dispatch = useDispatch();
  var history = useHistory();
  const state2 = useSelector((state: ApplicationState) => state.auth);
  if (state2.isLogging ?? false) {
    return (
      <LoadingComponent/>
    );
  } else {
    return (
      <div>
        <LogoComponent />
        <h2 className="text-center display-3">Logowanie</h2>
        <TextField
          label="Email"
          value={email}
          onChange={(event, newValue) => setEmail(newValue as string)}
        ></TextField>
        <TextField
          label="Hasło"
          type="password"
          canRevealPassword={true}
          value={password}
          onChange={(event, newValue) => setPassword(newValue as string)}
        ></TextField>
        <PrimaryButton
          className="mt-3"
          onClick={() => dispatch(actionCreators.login(email, password, history))}
        >
          Zaloguj
        </PrimaryButton>
        {state2.isLoggedIn ?? false ? <p>Zalogowano</p> : <></>}
      </div>
    );
  }
}
export default connect(
  (state: ApplicationState) => state.auth,
  actionCreators
)(Login);
