import * as React from "react";
import LinkCard from "./LinkCard";
import { ILinkModel } from "./Models/ILinkModel";
import { ApplicationState } from "./Redux/ReduxStore";
import { connect } from "react-redux";
import * as LinkStore from "./Redux/LinkRedux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Pagination } from "react-bootstrap";

import { GetQueryParams, SetQueryParams } from "./Helpers/UrlQueryHelper";
import LoadingComponent from "./loadingComponent";
import LinkFilterComponent from "./LinkFilterComponent";

export interface ILinkListProps {
  tagName: string;
}
interface MatchProps extends RouteComponentProps<ILinkListProps> {}
export interface ILinkListState {
  linkList: ILinkModel[];
  pageNumber?: number;
}
type prop = MatchProps & LinkStore.LinkState & typeof LinkStore.actionCreators;
class LinkList extends React.Component<prop, ILinkListState> {
  constructor(props: prop) {
    super(props);
    this.state = { linkList: [] };
  }

  componentDidMount() {
      this.props.getLinkList(GetQueryParams(this.props.history.location.search));
  }

  componentDidUpdate(prevProp: prop) {
    // console.log("prev",prevProp.tags??[]);
    // console.log("this",this.props.tags??[]);
    // console.log(equalsIgnoreOrder(this.props.tags??[],prevProp.tags??[]));
    if  ((prevProp.currentPage !== undefined && this.props.currentPage !== undefined && 
      Number(this.props.currentPage) !== Number(prevProp.currentPage)) ||
      !equalsIgnoreOrder(this.props.tags??[],prevProp.tags??[])
      ) {
  // console.log("tu fetch",{page: this.props.currentPage??1, tagName: this.props.tags??[]})
      this.props.getLinkList({page: this.props.currentPage??1, tagName: this.props.tags??[]}); 
    }
  }
public render(){
  return (  <><LinkFilterComponent></LinkFilterComponent>{this.renderContent()}</>)
}
  public renderContent() {
    if (this.props.isLoading??false) {
      return (
        <LoadingComponent/>
      );
    }

    let items: any[] = [];
   
    items.push(<Pagination.Prev key={"prev"} disabled={!((this.props.currentPage ?? 0)>1) } onClick={() => this.pageItemAdd(-1)}>Poprzednia</Pagination.Prev>);
    for (let number = 1; number <= (this.props.pageCount ?? 0); number++) {
      items.push(
        <Pagination.Item
          key={number}
          onClick={() => this.pageItemClick(number)}
          active={number === this.props.currentPage ?? 0}
        >
          {number}
        </Pagination.Item>
      );
    }
    items.push(<Pagination.Next key={"next"} disabled={(this.props.currentPage ?? 0) === this.props.pageCount??0 } onClick={() => this.pageItemAdd(1)}>Następna</Pagination.Next>);
    return (
      <div>
         <Button variant="outline-secondary"  size="sm" onClick={()=>this.openAllClicked()}>Otwórz wszystkie</Button> 
        {this.props.linkList?.map((x) => {
          return (
            <div key={x.linkId} id={"link_" + x.linkId}>
              <LinkCard key={x.linkId} link={x} />
            </div>
          );
        })}
        <Pagination className="d-flex flex-wrap">{items}</Pagination>
      </div>
    );
  }
  pageItemClick(page: number): void {

this.props.pageChange(page);
  }
  openAllClicked(){
    this.props.linkList?.forEach(x=> {window.open(x.url); console.log("try open",x.url)});
  }

  pageItemAdd(pageToAdd: number): void {
    this.pageItemClick(Number(this.props.currentPage??0) + Number(pageToAdd));
  }
}
const mapStateToProps = (state: ApplicationState) => {
  return state.link;
};
export default withRouter(
  connect(mapStateToProps, LinkStore.actionCreators)(LinkList as any)
);
const equalsIgnoreOrder = (a:string[], b:string[]) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of Array.from(uniqueValues)) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
}